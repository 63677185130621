@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

* {
  font-family: 'Lato', sans-serif;
}

html{
  overflow-y: scroll;
}
@media only screen and (max-width: 800px) {
  
  body h1{
    font-size: 11vw;
    width: 100%;
    line-height: 9vw;
  }
  body .maindiv{  
    width: 100%;
  }

  body .sound-container{
    width: 100%;
    padding-bottom: 1vh
  }
  body .video-container{
    width: 100%;
  }

  body h2{
    font-size: calc(1.4vw + 14px);
    margin-bottom: 50px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    width:80%;
    margin: auto;
    line-height: calc(6vw + 15px);
  }

  body .bio{
    text-align: justify;
    margin: auto;
    padding: 5px;
    width: 97%;
    font-size: 3.5vw;
  }

  body table{
    width: 100%;
    font-size: 3.5vw;
    text-align: center;
    border-spacing: 0px 1rem;
  }

  body .buttonw, body .buttonb, body .buttonc {
    color: black;
    width: 33.33%;
    height: 100%;
    text-decoration: none;
    font-weight: 1000;
    font-size: 3vw;
    font-family: 'lato', sans-serif;
    transition: ease-out .3s;
    line-height: 150px;
    /* text-align: center; */
  }

  body .buttonw:hover, body .buttonc:hover, body .buttonb:hover{
    background-color: #000000;
    cursor: grab;
    color: #ffffff;
    font-size: 4vw;
  }

  body .infotext > p{
    font-size: calc(1.6vw + 10px);
    margin: 0px;
    width: 100%;
    padding-top: 1vh;
    padding-bottom: 1vh;
  }
  body .video{
    height: 47vw;
  }
  body .buttonbox{
    margin-top: -1.5vh;
  }


}

body{
  text-align: center;
  width: 87%;
  margin: auto;
}

.maindiv{
  position: relative;
  z-index: 2;
  width: 700px;
  margin: auto;
}

h1{
  font-size: 95px;
  width: 100%;
  text-align: center;
  line-height: 90px;
}

h2{
  font-size: 30px;
  margin-bottom: 50px;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  width: 700px;
  margin: auto;
  line-height: 80px;
}

.bio{
  text-align: justify;
  margin: auto;
  width: 700px;
  font-size: 25px;
}

.biodiv{
  width: 100%;
  padding-top: 0vw;
  padding-bottom: 0vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-evenly;
}

table{
  width: 700px;
  font-size: 22px;
  text-align: center;
  table-layout: fixed;
  border-spacing: 10px 1.5rem;
}

.infotext{
  text-align: center;
  padding-top: 0vw;
  padding: 0vw;
  width: 100%;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  text-align: justify;
  font-size: 26px;
  margin: 0px
}

.buttonbox{
  margin-top: -30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;
  width: 100%;
  height: 170px;
  border: 6px solid black;
  color: black;
  /* box-shadow: 0px 0px 30px #888888; */
}

.buttonw, .buttonb, .buttonc {
  color: black;
  background-color: white;
  width: 100%;
  height: 100%;
  text-decoration: none;
  font-weight: 1000;
  font-size: 25px;
  font-family: 'lato', sans-serif;
  transition: ease-out .3s;
  line-height: 150px;
  /* text-align: center; */
}

.buttonw:hover, .buttonc:hover, .buttonb:hover{
  background-color: #000000;
  cursor: grab;
  color: #ffffff;
  font-size: 35px;
  
}

.workbox, .workbox2{
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 80%;
  height: 1000;
}

.worknav{
  box-sizing: border-box;
  border: 5px solid black;
  margin: auto;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 50px; */
}

.link{
  text-decoration: none;
  font-weight: 1000;
  color: black;
  font-size: 30px;
  transition-duration: 0.2s;
  width: 100%;
  height: 100%;
  line-height: 150px;
}

.link:hover{
  color: white;
  background-color: black;


}

.ballcontainer{
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.ball{
  /* margin: 50px; */
  width: 50px;
  height: 50px;
  /* border-radius: 100px; */
  background-color: white;
  transition-duration: 0.2s;
}
.ball:hover{
  background-color: black;
}

.sound-container{
  /* box-sizing: border-box;
  border: 6px solid black; */
  display: flex;
  flex-direction: column;
  width: 700px;
  margin: auto;
  padding-bottom: 40px
}

.video-container{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 700px;
  margin: auto;
  padding-bottom: 1vw;
  padding-top: 0vw;
}

.video{
  height: 395px;
  padding: 0px;
}

li{
  text-align: left;
  list-style: none;
}

a{
  text-decoration: none;
  color: rgb(0, 0, 0);
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
}

a:hover{
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  transition-duration: 0.2s;

}